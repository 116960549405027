import { EventInterface } from '@splidejs/splide';

export function lazyOnDemand( Splide, Components, options ) {
  const { on, off, bind, unbind } = EventInterface( Splide );

  function mount() {
    if (typeof options.lazyOnDemand != 'undefined' && options.lazyOnDemand) {
      on('move', (nextIndex, prevIndex) => {
        const slides = Splide.Components.Elements.slides;

        const filteredSlides = slides.filter((slide, index) => {
          return index <= nextIndex && index > prevIndex;
        });

        filteredSlides.forEach((slide) => {
          slide.querySelectorAll('img[data-splide-lazy]').forEach((img) => {
            const newSrc = img.getAttribute('data-splide-lazy');
            const newSrcSet = img.getAttribute('data-splide-lazy-srcset');

            if (isVisible(img)) {
              if (newSrcSet) {
                img.srcset = newSrc;
              }

              if (newSrc) {
                img.src = newSrc;
              }
            }
          })
        });
      })
    }
  }

  function isVisible(elem) {
    return !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length );
  }

  return {
    mount,
  };
}
