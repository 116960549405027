import Cookies from 'js-cookie';

export default class CookiesHandler {
  handleAccept() {
    window.addEventListener('CookieScriptAccept', () => {
      this.setLuigisBoxCookie();
    });
    window.addEventListener('CookieScriptAcceptAll', () => {
      this.setLuigisBoxCookie();
    });

    // already accepted
    this.setLuigisBoxCookie();
  }

  setLuigisBoxCookie() {
    if (typeof CookieScript != 'undefined') {
      const state = CookieScript.instance.currentState();

      if (state.categories.indexOf('targeting') !== -1) {
        Cookies.set('lbx_consent_cookie', 'granted', { expires: 90 });
      }
    }
  }
}
