import MailAlertsHelper from './components/MailAlertsHelper';

$(() => {
  $(document).on('click', '.js-mail-alerts', function(e){
    e.preventDefault();
    const data = e.currentTarget.dataset;
    MailAlertsHelper.addNotificationBy(data.url, data);
  });

  $(document).on('submit', '.js-mailalerts-user-not-logged-form', (e) => {
    e.preventDefault();
    MailAlertsHelper.submitForm(e.currentTarget);
    return false;
  });
});
