import CmsSearchHelper from './CmsSearchHelper';

($ => {
  const namesArray = [];
  const listItems = $('.cms-page-list__text');
  listItems.each((index, item) => {
    namesArray.push({
      text: $(item).data('text'),
      element: item,
      href: $(item).attr('href'),
    });
  });

  const cmsSearchHelper = new CmsSearchHelper(namesArray);

  const activateList = (list) => {
    list.forEach((item, index) => {
      $(item.element).parent().show();
      $(item.element).html(item.text.replace(searchPhrase, `<b>${searchPhrase}</b>`));
      $(item.element).closest('.collapse').collapse('show');
    });
  };

  $(document).on('keyup change', '.js-cms-category-search', (e) => {
    e.preventDefault();
    const searchPhrase = $(e.currentTarget).val();
    const result = cmsSearchHelper.search(searchPhrase);
    if (result.length && searchPhrase.length > 1) {
      $('.cms-page-list__item').hide();
      result.forEach((item, index) => {
        $(item.element).parent().show();
        $(item.element).html(item.text.replace(searchPhrase, `<b>${searchPhrase}</b>`));
        $(item.element).closest('.collapse').collapse('show');
      });
    } else {
      listItems.each((index, item) => {
        $(item).parent().show();
        $(item).html($(item).data('text'));
        $(item).closest('.collapse').collapse('hide');
      });
    }
  });
})(jQuery);
