import noUiSlider from 'nouislider';

export default class PriceSlider {
  constructor(selector) {
    this.selector = selector;
    $(selector).each((index, item) => {
      this.initSlider(item);
    });
  }

  initSlider(element) {
    const container = $(element).closest('.slider');
    const inputLeft = container.find('.slider__input--left');
    const inputRight = container.find('.slider__input--right');

    noUiSlider.create(element, {
      start: [inputLeft.val(), inputRight.val()],
      range: {
        min: container.data('min'),
        max: container.data('max'),
      },
    });

    element.noUiSlider.on('update', (values, handle) => {
      const value = values[handle];
      if (handle) {
        inputRight.val(value);
      } else {
        inputLeft.val(value);
      }
    });

    $(this.selector).find('.slider__input').on('change', () => {
      element.noUiSlider.set([inputLeft.val(), inputRight.val()]);
    });
  }
}
