import AlertModal from './AlertModal';

export default class MailAlertsHelper {

  /**
   * @param url
   * @param {object} data
   * @param {number|string} data.id_product
   * @param {number|string} data.id_product_attribute
   * @param {string} data.popup_title
   * @param {string} data.email
   * @returns {boolean}
   */
  static addNotificationBy(url, data) {
    $.ajax({
      type: 'POST',
      url,
      data,
      success: (resp) => {
        resp = JSON.parse(resp);
        MailAlertsHelper.setNofiticationFormValues(data, resp);
        $('#mailalerts-modal').modal({ show: true });
      },
    });
    return false;
  }

  static showNofiticationForm(url, data) {
    MailAlertsHelper.setNofiticationFormValues(data);
    $('#mailalerts-modal').modal({ show: true });
  }

  static setNofiticationFormValues(data, response = null) {
    $('.js-mailalerts-input-id_product').val(data.id_product);
    $('.js-mailalerts-input-id_product_attribute').val(data.id_product);
    $('.js-mailalert-errors').html('').hide();

    if (response !== null) {
      $('.js-mailalerts-user-logged-text').html(MailAlertsHelper.returnHtmlTagsInString(response.message));
    }
  }

  static submitForm(form) {
    const data = {
      id_product: $(form).find('.js-mailalerts-input-id_product').val(),
      id_product_attribute: $(form).find('.js-mailalerts-input-id_product_attribute').val(),
      customer_email: $(form).find('.js-mailalerts-input-customer_email').val(),
    };
    const url = $(form).attr('action');
    $.ajax({
      type: 'POST',
      url,
      data,
      success: (resp) => {
        resp = JSON.parse(resp);
        $(form).find('.js-mailalert-errors').html('<article class="alert alert-info" role="alert" data-alert="success">'+resp.message+'</article>').show();
      },
    });
  }

  static returnHtmlTagsInString(string) {
    const reg1 = new RegExp('&lt;', 'g');
    const reg2 = new RegExp('&gt;', 'g');
    return string.replace(reg1, '<').replace(reg2, '>');
  }

}
