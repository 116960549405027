/**
 * Alert modal parameters type
 * @typedef {Object} AlertModalParameters
 * @property {string} title
 * @property {string} text
 * @property {string} icon
 */
export default class AlertModal {
  constructor() {
    this.elements = {
      modal: $('#alert-modal'),
      title: $('.js-alert-modal-title'),
      icon: $('.js-alert-modal-icon'),
      text: $('.js-alert-modal-text'),
    };

    this.iconInitialClass = this.elements.icon.attr('class');
  }

  /**
   * @param {AlertModalParameters} params
   */
  show(params) {
    this.setupValues(params);
    this.elements.modal.modal({ show: true });
  }

  /**
   * @param {AlertModalParameters} params
   */
  setupValues(params) {
    this.clearModal();
    this.elements.title.html(params.title);
    this.elements.icon.addClass(`icon-${params.icon}`);
    this.elements.text.html(this.returnHtmlTagsInString(params.text));
    if (!params.text) {
      this.elements.modal.find('.modal-body').css({ display: 'none' });
      this.elements.modal.find('.modal-header').removeClass('modal-small-padding-bottom');
    } else {
      this.elements.modal.find('.modal-body').css({ display: 'inline' });
      this.elements.modal.find('.modal-header').addClass('modal-small-padding-bottom');
    }
  }

  returnHtmlTagsInString(string) {
    if (string) {
      const reg1 = new RegExp('&lt;', 'g');
      const reg2 = new RegExp('&gt;', 'g');
      return string.replace(reg1, '<').replace(reg2, '>');
    } else {
      return '';
    }
  }

  clearModal() {
    this.elements.icon.attr('class', this.iconInitialClass);
  }
}
