import TimeCounter from './time-counter';

$(() => {

  $('.time-counter').each((index, item) => {
    const timeCounter = new TimeCounter($(item).data('to-time'), 1000);

    timeCounter.on('change', (data) => {
      $(item).find('.time-counter__number--hours').html(data.hours);
      $(item).find('.time-counter__number--minutes').html(data.filled.minutes);
      $(item).find('.time-counter__number--seconds').html(data.filled.seconds);
    });

    timeCounter.on('end', () => {});
  });
});
