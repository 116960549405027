import debounce from 'lodash.debounce';
import EventEmitter from 'events';

export default class Header extends EventEmitter{
  constructor(args) {
    super();
    this.headerElement = args.headerElement;
    this.floatingElement = args.floatingElement;
    this.stickyBreakpointPosition = args.stickyBreakpointPosition;
    this.stickyClass = args.stickyClass;
    this.headerWrapper = args.headerWrapper;
    this.enabled = false;

  }

  init() {
    this.refreshContainerHeight();
    $(window).on('scroll', () => {
      this.watch();
    }).trigger('scroll');

    $(window).on('resize', debounce(() => {
      this.disableSticky();

      this.refreshContainerHeight();
      if (this.shouldBeSticky()) {
        this.enableSticky();
      }
    }, 50));
    this.emit('afterInit', this);
  }

  refreshContainerHeight() {
    $(this.headerWrapper).css('height', '');
    $(this.headerWrapper).height(this.headerElement.height());
  }

  watch() {
    if (this.shouldBeSticky()) {
      if (!this.enabled) {
        this.enableSticky();
      }
    } else if (this.enabled) {
      this.disableSticky();
    }
  }

  shouldBeSticky() {
    return $(window).scrollTop() > this.stickyBreakpointPosition;
  }

  enableSticky() {
    this.emit('beforeStickyEnable', this);
    this.headerElement.addClass(this.stickyClass);
    this.enabled = true;
    this.emit('afterStickyEnable', this);
    // $(this.headerWrapper).height(this.headerElement.height());
  }

  disableSticky() {
    this.emit('beforeStickyDisable', this);
    this.headerElement.removeClass(this.stickyClass);
    this.enabled = false;
    this.emit('afterStickyDisable', this);
  }
}
