/**
 * @typedef {Object} CmsSearchObject
 * @property {HTMLElement} element
 * @property {string} text
 * @property {string} href
 */

export default class CmsSearchHelper {
  /**
   * @param {Array<CmsSearchObject>} data
   */
  constructor(data) {
    this.data = data;
  }

  /**
   * Returns array of data objects maching string
   * @param {string} text
   */
  search(text) {
    return this.data.filter((item, index) => this.isItemProper(text, item));
  }

  /**
   * @param {CmsSearchObject} item
   */
  isItemProper(text, item) {
    return item.text.indexOf(text) !== -1;
  }
}
