import FormFieldCollapse from './components/FormFieldCollapse';
import EventEmitter from 'events';

const invoiceManager = {
  init() {
    this.collapse = new FormFieldCollapse({
      collapse: '.form-invoice-only',
      trigger: '.js-show-form-invoice',
      activeOnStart: $('.js-show-form-invoice').prop('checked'),
    });

    this.collapse.initEvents();

    /**
     * Because invoice fields are hidden we have to toggle enable / disable
     * required flag in order to not block the whole form.
     */
    this.collapse.on('afterHide', function () {
      $('input[name="invoice_company"]').removeAttr();
      $('input[name="invoice_vat_number"]').removeAttr();
      $('input[name="invoice_address1"]').removeAttr();
      $('input[name="invoice_postcode"]').removeAttr();
      $('input[name="invoice_city"]').removeAttr();
    })

    this.collapse.on('afterShow', function () {
      $('input[name="invoice_company"]').attr('required', 'required');
      $('input[name="invoice_vat_number"]').attr('required', 'required');
      $('input[name="invoice_address1"]').attr('required', 'required');
      $('input[name="invoice_postcode"]').attr('required', 'required');
      $('input[name="invoice_city"]').attr('required', 'required');

      $('html, body').animate({
        scrollTop: $('.form-invoice-only').offset().top
      }, 500);
    })
  },
};

$(document).ready(() => {
  invoiceManager.init();
});

$(document).on('click', '.js-copy-data-from-address-to-invoice-address', (e) => {
  e.preventDefault();
  const button = $(e.currentTarget);
  const form = button.closest('form');

  form.find('input').each((index, item) => {
    const name = $(item).attr('name');
    const similarInvoiceInput = form.find(`[name=invoice_${name}]`);
    if (similarInvoiceInput.length) {
      similarInvoiceInput.val($(item).val());
      similarInvoiceInput.trigger('change').focus();
    }
  });
});

$(document).on('change', '.js-different-invoice-address', function(e){
  e.preventDefault();
  const urlBase = $(this).data('change-url');
  const checkboxParameter = `use_same_address=${$(this).prop('checked') ? 0 : 1}`;
  const stayOnAddressStep = 'stay_on_address_step=1';
  const addressParameter = `id_address_delivery=${$(this).data('id_address_delivery')}`;
  const newUrl = `${urlBase}?${checkboxParameter}&${stayOnAddressStep}&${addressParameter}`;

  window.location = newUrl;
});
