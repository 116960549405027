import Cookies from 'js-cookie';

export default class ProductList {

  /**
   * Runs every product list initialization
   * @param container
   */
  static init(container) {
    this.initSpinner(container);
    this.initViewButtons(container);
    this.initSelects(container);
    this.initProductListDisplay(container);
    this.initFilterCollapsing(container);
  }


  static initSpinner(container) {
    $(container).find('.spinner__input').each((index, item) => {
      if (!$(item).parent().hasClass('bootstrap-touchspin')) {
        $(item).TouchSpin({
          buttondown_class: 'btn js-touchspin',
          buttonup_class: 'btn js-touchspin',
          step: 1,
          verticalbuttons: true,
          min: parseInt($(item).attr('min'), 10),
          max: parseInt($(item).attr('max'), 10),
        });

        $(item).on('touchspin.on.max', () => {
          $(item).closest('.spinner').tooltip('show');
        });

        $(item).on('change', () => {
          $(item).closest('.spinner').tooltip('dispose');
        });
      }
    });
  }

  static initViewButtons(container) {
    $(document).on('click', '.js-view-change-to-grid', (e) => {
      e.preventDefault();
      this.setGridMode(container);
    });

    $(document).on('click', '.js-view-change-to-list', (e) => {
      e.preventDefault();
      this.setListMode(container);
    });

    prestashop.on('varnish:after-modules-reload', () => {
      if ($(container).find('.js-view-change-to-list').length) {
        if (typeof Cookies.get('isListDisplay') !== 'undefined' && Cookies.get('isListDisplay') === "1") {
          this.setListMode(container);
        } else {
          this.setGridMode(container);
        }
      }
    });
  }

  static initSelects(container) {
    $(container).find('select:visible').niceSelect();
  }

  static setListMode(container) {
    $(container).find('.js-view-change-to-list').addClass('is-active');
    $(container).find('.js-view-change-to-grid').removeClass('is-active');
    $(container).find('.product-miniature').addClass('product-miniature--list');
    $(container).find('.card-product').addClass('card-product--list');
    Cookies.set('isListDisplay', 1);
  }

  static setGridMode(container) {
    $(container).find('.js-view-change-to-grid').addClass('is-active');
    $(container).find('.js-view-change-to-list').removeClass('is-active');
    $(container).find('.product-miniature').removeClass('product-miniature--list');
    $(container).find('.card-product').removeClass('card-product--list');
    Cookies.set('isListDisplay', 0);
  }

  static initProductListDisplay(container) {
    if (typeof Cookies.get('isListDisplay') !== 'undefined' && Cookies.get('isListDisplay') === "1" && $(container).find('.js-view-change-to-list').length) {
      this.setListMode(container);
    }

    $(window).resize(() => {
      if (screen.width < 576 && Cookies.get('isListDisplay') === "1") {
        this.setGridMode($('#content-wrapper'));
      }
    });
  }

  static initFilterCollapsing(container) {
    const collapseButton = $(container).find('.js-facet-collapse-button');
    const facetRow = $(container).find('.facet-row');
    facetRow.removeClass('is-uncollapsed');
    const facetRowInnerHeight = facetRow.innerHeight();

    if (facetRow.children().length > 8) {
      collapseButton.show().addClass('is-active');
      facetRow.removeClass('is-uncollapsed');
    } else {
      collapseButton.hide();
      facetRow.addClass('is-uncollapsed');
    }

    collapseButton.on('click', (e) => {
      e.preventDefault();
      $(e.currentTarget).toggleClass('is-active');
      facetRow.toggleClass('is-uncollapsed');
    });
  }
}
