export default class WishlistBridge {
  add(url) {
    const tmpUrl = `${url}&action=wishListAdd`;
    return this.send(tmpUrl);
  }

  remove(url) {
    const tmpUrl = `${url}&action=wishListRemove`;
    return this.send(tmpUrl);
  }

  newWishlist(url, name) {
    return this.send(url, { name });
  }

  deleteWishList(url) {
    const tmpUrl = `${url}`;
    return this.send(tmpUrl);
  }

  showModal(url) {
    const tmpUrl = `${url}&action=wishListChooseModal`;
    return this.send(tmpUrl);
  }

  /**
   * @param {String} url
   * @param {Object} data
   * @returns {Promise<any>}
   */
  send(url, data = {}) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url,
        data,
        async: true,
        dataType: 'json',
        success(response) {
          resolve(response);
        },
        error(response) {
          reject(response);
        },
      });
    });
  }
}
