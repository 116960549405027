$(document).ready(function (){
  let breadcrumb = $('.breadcrumb');
  if (breadcrumb.length > 0) {
    if (checkOverflow(breadcrumb[0])) {
      $('.breadcrumb-nav-right').show();
    }

    $('.breadcrumb-nav-right').click(function () {
      $('.breadcrumb').animate({scrollLeft: 500}, 500);
      $('.breadcrumb-nav-right').hide();
      $('.breadcrumb-nav-left').show();
    });

    $('.breadcrumb-nav-left').click(function () {
      $('.breadcrumb').animate({scrollLeft: -500}, 500);
      $('.breadcrumb-nav-right').show();
      $('.breadcrumb-nav-left').hide();
    });
  }

  function checkOverflow(el) {
    return el.clientWidth < el.scrollWidth;
  }
});
