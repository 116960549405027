import Wishlist from './Wishlist';
import AlertModal from '../AlertModal';

$(document).ready(() => {
  const wishlist = new Wishlist();
  prestashop.wishlist = wishlist;

  const alertModal = new AlertModal();
  const defaultReject = (response) => {
    alertModal.show({
      icon: 'cancel icon-color-danger',
      title: response.message,
    });

    return response;
  };

  const defaultFulfilled = (response) => {
    alertModal.show({
      icon: 'check icon-color-success',
      title: response.message,
    });

    return response;
  };

  $(document).on('click', '.wishlist-add', function (e) {
    e.preventDefault();
    const $el = $(this);
    wishlist
      .add($el.attr('href'))
      .then((response) => {
        $el.removeClass('wishlist-add');
        $el.addClass('wishlist-remove');
        return response;
      })
      .then(defaultFulfilled, defaultReject);
  });

  $(document).on('click', '.wishlist-remove', function (e) {
    e.preventDefault();
    const $el = $(this);
    wishlist
      .remove($el.attr('href'))
      .then((response) => {
        $el.removeClass('wishlist-remove');
        $el.addClass('wishlist-add');
        return response;
      })
      .then(defaultFulfilled, defaultReject);
  });

  $(document).on('click', '.wishlist-show-modal', function (e) {
    e.preventDefault();
    const $el = $(this);
    if (!$('#wnet_wishlist-modal').hasClass('show')) {
      wishlist
        .showModal($el.attr('href'))
        .then((response) => {
          $('#wnet_wishlist-modal').remove();
          $('body').append(response.popup);
          $('#wnet_wishlist-modal').modal('show');
          return response;
        }, defaultReject);
    }
  });

  $(document).on('click', '.js-wishlist-remove-with-miniature', function (e) {
    e.preventDefault();
    const $el = $(this);
    wishlist
      .remove($el.attr('href'))
      .then((response) => {
        $el.closest('.product-miniature').remove();
        const remainingProducts = $('.product-miniature');
        if (remainingProducts.length === 0) {
          window.location.reload();
        }
        return response;
      })
      .then(defaultFulfilled, defaultReject);
  });

  $(document).on('click', '.js-create-new-wishlist', function(e){
    e.preventDefault();
    const $el = $(this);
    wishlist
      .newWishlist($el.closest('form').attr('action'), $('#wnet_wishlist-new-name').val())
      .then((response) => {
        $('#wnet_wishlist-wishlists-page-content').html(response.list);
      }, (response) => {
        $('#wnet_wishlist-modal').modal('hide');
        defaultReject(response);
      });
  });

  $(document).on('click', '.js-delete-wishlist', function(e){
    e.preventDefault();
    const $el = $(this);
    wishlist
      .deleteWishlist($el.attr('href'))
      .then((response) => {
        $('#wnet_wishlist-wishlists-page-content').html(response.list);
        defaultFulfilled(response);
      }, defaultReject);
  });

  $(document).on('click', '.js-create-new-wishlist-modal', function(e){
    e.preventDefault();
    const $el = $(this);
    wishlist
      .newWishlist($el.closest('form').attr('action'), $('#wnet_wishlist-modal-new-name').val())
      .then((response) => {
        $('#wishlist-modal-form-container').html(response.form);
      }, (response) => {
        $('#wnet_wishlist-modal').modal('hide');
        defaultReject(response);
      });
  });

  $(document).on('click', '.js-add-to-chosen-wishlist', function(e) {
    e.preventDefault();
    const $el = $(this);
    const chosenIds = $('.js-wishlist-modal-checkbox:checked').toArray().map(function(item){ return item.value; });

    wishlist
      .add($el.attr('href') + '&id_wishlists=' + chosenIds.join(','))
      .then((response) => {
        $el.removeClass('wishlist-add');
        $el.addClass('wishlist-remove');
        $('#wnet_wishlist-modal').modal('hide');
        defaultFulfilled(response);
      }, (response) => {
        $el.removeClass('wishlist-add');
        $el.addClass('wishlist-remove');
        $('#wnet_wishlist-modal').modal('hide');
        defaultReject(response);
      });
  });

  $(document).on('click', '.js-add-all-to-cart', function(e) {
    e.preventDefault();
    const $el = $(this);
    wishlist
      .send($el.attr('href'))
      .then((response) => {
        prestashop.emit('updateCart', {
          reason: {
            id_product_attribute: 0,
            id_product: 0,
            action: 'add-to-cart',
            actionSource: '1',
          },
        });
        return response;
      })
      .then(defaultFulfilled, defaultReject);
  });
});
