import $ from 'jquery';

import Splide from '@splidejs/splide';

Splide.defaults = {
  pagination: false,
  speed: 600,
};

class PageSlider {
  constructor() {
    this.observeElementClass = 'slider-observed';
    this.selfInitializedSlidersSelector = '.js-slide-self-init';
  }

  init() {
    const self = this;

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(({ intersectionRatio, target }) => {
        if (intersectionRatio > 0) {
          self.observer.unobserve(target);
          self.initSlider(target);
        }
      });
    });

    this.observerElements();
  }

  initSlider(target) {
    if (!(target.classList.contains('custom-splide'))) {
      new Splide(target).mount();
    }
  }

  observerElements() {
    var elms = document.querySelectorAll(this.selfInitializedSlidersSelector);

    for ( var i = 0; i < elms.length; i++ ) {
      const elem = elms[i];

      if (!elem.classList.contains(this.observeElementClass)) {
        this.observer.observe(elem);
        elem.classList.add(this.observeElementClass);
      }
    }
  }

  refresh() {
    this.observerElements();
  }
}

export default PageSlider;
