($ => {
  $(document).on('click', '.cms-collapse__title', (e) => {
    e.preventDefault();
    let collapse = $(e.currentTarget).next('.collapse');
    if (!collapse.length) {
      collapse = $(e.currentTarget).parent().next('.collapse');
    }

    if (!collapse.length) {
      collapse = $(e.currentTarget.hash);
    }
    collapse.collapse('toggle');
    $(e.currentTarget).toggleClass('is-active');
  });

  $(document).on('click', '.cms-collapse-list-item__trigger', (e) => {
    e.preventDefault();
    $(e.currentTarget).parent().toggleClass('is-active');
  });
})(jQuery);
